import React, { useEffect } from "react";
import { useRouter } from "next/router";
import Cookies from "js-cookie";

const RedirectQuery = ({ query }): any => {
  const router = useRouter();
  if (query) {
    useEffect(() => {
      router.push({
        pathname: "/sell-your-bag-v2",
        query: JSON.parse(query),
      });
    }, [query]);
  } else {
    useEffect(() => {
      router.push("/sell-your-bag-v2");
    }, []);
  }
  return null;
};

const Home = (): React.ReactElement => {
  return (
    <div>
      <RedirectQuery
        query={Cookies.get("query", {
          domain: process.env.NEXT_PUBLIC_WEB_APP_URL,
        })}
      />
    </div>
  );
};

export default Home;
